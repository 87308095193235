import lottie from "lottie-web";
import React from "react";
import { useEffect, useRef } from "react";

import animation from "../content/lottie_contact.json";

interface Props {
  onClose: () => void;
}

const ContactDialog = ({ onClose }: Props) => {
  const animationContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (animationContainer.current) {
      lottie.loadAnimation({
        container: animationContainer.current,
        loop: true,
        autoplay: true,
        animationData: animation,
      });
    }
  }, [animationContainer]);

  return (
    <div className="max-w-md flex flex-col justify-center place-items-center">
      <div className="w-64" ref={animationContainer} />
      <div className="text-ui-3 text-lg my-2 text-center font-semibold">Thanks for reaching</div>
      <div className="text-center">
        I really appreciate that. I'll try my best to write you back as soon as possible.
      </div>
      <div
        className="cursor-pointer px-9 py-2 mt-4 rounded-full shadow-lg from-blue-500 to-blue-400 bg-gradient-to-r text-white"
        onClick={onClose}
      >
        Stay cool
      </div>
    </div>
  );
};

export default ContactDialog;
