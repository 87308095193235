import lottie from "lottie-web";
import React from "react";
import { useEffect, useRef } from "react";

import animation from "../content/lottie_error.json";

interface Props {
  onClose: () => void;
}

const ErrorDialog = ({ onClose }: Props) => {
  const animationContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (animationContainer.current) {
      lottie.loadAnimation({
        container: animationContainer.current,
        loop: true,
        autoplay: true,
        animationData: animation,
      });
    }
  }, [animationContainer]);

  return (
    <div className="max-w-md flex flex-col justify-center place-items-center">
      <div className="w-36" ref={animationContainer} />
      <div className="text-ui-3 text-lg my-2 text-center font-semibold">Ooops ! There is something wrong</div>
      <div className="text-center">
        I'm aware of the issue and this will be fixed soon. Please try again later, sorry for the inconvenience.
      </div>
      <div className="cursor-pointer px-9 py-2 mt-4 rounded-full shadow-lg bg-black text-white" onClick={onClose}>
        Go back
      </div>
    </div>
  );
};

export default ErrorDialog;
