import axios from "axios";
import { StaticImage } from "gatsby-plugin-image";
import React, { FormEvent, useMemo, useState } from "react";
import { HiOutlineMail } from "react-icons/hi";
import { IoBookOutline } from "react-icons/io5";
import Modal from "react-modal";

import ErrorDialog from "src/components/dialog-error";
import ContactDialog from "src/components/dialog-thank";
import Layout from "src/components/layout";
import SEO from "src/components/seo";

type FormState = "idle" | "submitting" | "succeeded" | "error";

Modal.setAppElement(`#___gatsby`);

const AboutPage = () => {
  const [formState, setFormState] = useState<FormState>("idle");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const disabledSubmitButton = useMemo(
    () => formState === "submitting" || name.trim() === "" || email.trim() === "" || message.trim() === "",
    [formState, name, email, message],
  );

  const handleOnSubmit = (e: FormEvent) => {
    e.preventDefault();
    setFormState("submitting");
    axios
      .post("/.netlify/functions/contact", JSON.stringify({ name, email, message }))
      .then(_ => {
        setFormState("succeeded");
      })
      .catch(_ => {
        setFormState("error");
      });
  };

  const closeModal = () => {
    setFormState("idle");
  };

  return (
    <Layout>
      <SEO title="Silvercast | About" />
      <div className="flex flex-col justify-center lg:flex-row">
        <div className="flex-grow">
          <div className="text-5xl text-ui-1 font-extrabold">
            <span className="underline">Value</span> isn't created out of thin air. It is crafted or built.
          </div>
        </div>
        <div className="flex-shrink lg:ml-8">
          <StaticImage src="../content/work.svg" alt="Silvercast" placeholder="blurred" />
        </div>
      </div>
      <div className="text-xl text-ui-3 font-light mt-12">
        Silvercast, a technology conqueror, a life surfer, a thinker.
      </div>
      <div className="text-xl text-ui-3 font-light mt-4">
        I'm happy you're here. You may call me Silvercast, my nickname since forever. I work in computer software
        industry in Göteborg city, Sweden and maintain this blog when I have spare time. You should know that I'm
        totally passionate about creating value for others and that, in my mind, doesn't have to limit to our daily
        jobs. Here you may find work insights, or some photos, notes on how one can stay positive.
      </div>

      <form noValidate onSubmit={handleOnSubmit}>
        <div className="mt-8 flex flex-col md:flex-row border rounded-md">
          <div className="p-6 pr-12 bg-blue-50  flex-shrink-0 flex flex-col">
            <div className="text-ui-1 text-2xl font-semibold">Contact</div>
            <div className="text-ui-1 font-normal mt-2">Let's get in touch</div>
          </div>
          <div className="p-6 w-full">
            <div className="border rounded-md w-full flex place-items-center px-3 py-2 mb-2">
              <div className="mr-2">
                <IoBookOutline color="gray" />
              </div>
              <input
                type="text"
                id="name"
                name="name"
                required
                placeholder="Your Name"
                value={name}
                onChange={event => setName(event.target.value)}
                className="w-full outline-none focus:outline-none"
              />
            </div>
            <div className="border rounded-md w-full flex place-items-center px-3 py-2 mb-2">
              <div className="mr-2">
                <HiOutlineMail color="gray" />
              </div>
              <input
                type="text"
                id="identity"
                name="identity"
                required
                placeholder="Your email address"
                value={email}
                onChange={event => setEmail(event.target.value)}
                className="w-full outline-none focus:outline-none"
              />
            </div>
            <div className="border rounded-md w-full px-3 py-2">
              <textarea
                required
                placeholder="Your message"
                value={message}
                onChange={event => setMessage(event.target.value)}
                rows={9}
                className="w-full outline-none focus:outline-none"
              />
            </div>
            <div>
              <button
                type="submit"
                id="comment"
                disabled={disabledSubmitButton}
                className={`px-9 py-2 mt-4 rounded-full shadow-lg ${
                  disabledSubmitButton ? "bg-gray-500" : "from-blue-500 to-blue-400 bg-gradient-to-r"
                } text-white`}
              >
                Send
              </button>
            </div>
          </div>
        </div>
      </form>
      <Modal
        isOpen={formState === "succeeded" || formState === "error"}
        onRequestClose={closeModal}
        closeTimeoutMS={300}
        style={{
          overlay: {
            backgroundColor: "rgba(1, 1, 1, 0.58)",
          },
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
          },
        }}
      >
        {formState === "succeeded" && <ContactDialog onClose={closeModal} />}
        {formState === "error" && <ErrorDialog onClose={closeModal} />}
      </Modal>
    </Layout>
  );
};

export default AboutPage;
